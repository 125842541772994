<template>
    <v-card class="mx-auto" :disabled="saving || isDisabled" tile flat>
        
        <v-card-text class="pa-4 pt-0 pa-md-10 pt-md-0">
            <v-container pa-0 fluid>
                <v-row>
                    <v-col cols="12" class="pt-0">
                        
                        <ValidationObserver ref="observer" tag="form">
                            <div v-show="false">
                                <div v-for="(field, fkey) in fields"
                                    :key="fkey">
                                    <ValidationProvider
                                        v-slot="{ errors }"
                                        :name="field.meta | formalText"
                                        rules="required"
                                        :vid="field.meta">
                                        <v-text-field
                                            v-model="resourceObj[field.meta]"
                                            :error-messages="errors"
                                            :label="field.label">
                                        </v-text-field>
                                    </ValidationProvider>
                                </div>
                            </div>
                        </ValidationObserver>

                        <LoanDetails 
                            ref="loanDetails" 
                            @submit="handleLoanDetailsSubmit"
                        />

                        <template v-if="loanAmountHasValue">

                                <template v-if="!isLoading">

                                    <template v-if="topLenders.length > 0">
                                        <TopLoanMatches
                                            @show-info="showInfoDialog" 
                                            @show-apply="showApplyModal" 
                                            :hide-lender="hideLenderIndentity"
                                            :data="topLenders"
                                            :requiredAmount="requiredAmount"/>

                                        <div class="mb-5" v-if="vb.smAndUp">
                                            <div class="d-flex align-center">
                                                <div>
                                                    <h1 class="flex__step-header-title" v-if="vb.mdAndUp">Other Results</h1>
                                                    <h1 class="font-size-16 font-weight-medium" v-else>Other Lender options</h1>
                                                </div>
                                                <v-spacer></v-spacer>
                                                <div class="d-flex align-center">
                                                    <CompareLenders
                                                        :selected-lenders="selectedLendersToCompare"
                                                        :selected-lender="getSelectedLender"
                                                        :selected-count="selectedLendersToCompareCount"
                                                        :hide-lender="hideLenderIndentity"
                                                        :required-amount="requiredAmount"
                                                        :disabled="isDisabled">
                                                    </CompareLenders>
                                                </div>
                                            </div>
                                        </div>

                                        <OtherResults 
                                            v-if="vb.smAndUp"
                                            @show-info="showInfoDialog" 
                                            @show-apply="showApplyModal" 
                                            :data="otherLenders"
                                            :selected-lender="getSelectedLender"
                                            :hide-lender="hideLenderIndentity"
                                        />
                                    </template>
                                    <template v-else>
                                        <v-card tile flat class="rounded-lg mx-auto" max-width="700">
                                            <v-card-text class="text-center pa-0 pa-md-66">
                                                <v-icon size="200" color="">mdi-hand-heart-outline</v-icon>
                                                <h2 class="mt-5 mb-5">Based on the information provided, we are unable to provide you with an immediate quote. </h2>
                                                <!-- <p class="mb-2">However, we can still assist.</p> -->
                                                <!-- <p class="mb-5">To have one of our specialists contact you to discuss a solution, please click the <strong>Submit</strong> button below. </p> -->
                                                <p class="font-weight-bold mb-5"> Don't worry, this will not impact your credit score.</p>
                                            </v-card-text>
                                        </v-card>
                                    </template>

                                </template>
                                <template v-else>
                                    <div class="text-center d-block ">
                                        <h1 class="flex__step-header-title line-height-32 mb-2" v-if="vb.mdAndUp">Loading</h1>
                                        <h1 class="font-size-20 mb-2" v-else>Loading</h1>
                                        <p>Please wait...</p>
                                    </div>
                                </template>

                        </template>
                    </v-col>

                    <v-col  class="mb-5" cols="12" v-if="topLenders.length > 0 && vb.smAndDown">
                        <v-row no-gutters>
                            <v-col cols="2">
                                <v-icon x-large>mdi-star</v-icon>
                            </v-col>
                            <v-col cols="10" class="px-2">
                                <h3 class="mb-3">All Fee Included</h3>
                            </v-col>
                        </v-row>
                        <v-row no-gutters>
                            <v-col cols="2">
                                <v-icon x-large>mdi-star</v-icon>
                            </v-col>
                            <v-col cols="10" class="px-2">
                                <h3 class="mb-3">This won't impact your credit score</h3>
                            </v-col>
                        </v-row>
                    </v-col>

                    <v-col cols="12" class="mt-1 mt-md-5">
                        <Disclaimer :settings="blockSettings"/>  
                    </v-col>
                    
                </v-row>
            </v-container>

        </v-card-text>


        <!-- More Info Modal -->

        <MoreInfoModal
            v-if="dialogData"
            :dialog="dialog"
            :data="dialogData"
            :loading="saving"
            :selected-lender="getSelectedLender"
            :required-amount="requiredAmount"
            :hide-lender="hideLenderIndentity"
            @show-apply="showApplyModal" 
            :user-input="userInput"
            @close="dialog = false"
        />

        <!-- Compare Lenders -->

        <!-- <RequestCallback3
            :person="person"
            :show-dialog="callDialog"
            @save-person="setSelected(lenderName, lenderData)"
            @close-modal="callDialog = false" /> -->
        <SubmitApplicationTo :lenderObj="lenderObj" :dialog="callDialog" @close-modal="closeModal"/>

        <Portal to="component-header-call-back">
            <RequestCallback1 @save-person="handleContinue" />
        </Portal>
    
    </v-card>
</template>

<script>

    import mixins from '~/components/v1/QuoteResult6/_mixins'
    import subscriptions from '~/components/shared/subscriptions'

    import { mapActions, mapGetters, mapState, mapMutations } from 'vuex'

    import { isEmpty } from 'lodash'

    import LoanDetails from '~/components/v1/QuoteResult6/_LoanDetails'
    import TopLoanMatches from '~/components/v1/QuoteResult6/_TopLoanMatches'
    import OtherResults from '~/components/v1/QuoteResult6/_OtherResults'
    import Disclaimer from '~/components/v1/QuoteResult6/_Disclaimer'
    import CompareLenders from '~/components/v1/QuoteResult6/_CompareLenders'
    import MoreInfoModal from '~/components/v1/QuoteResult6/_MoreInfoModal'
    import {store, actions} from '~/components/v1/QuoteResult6/__store'
    import lenderSlugAlgo from '~/config/lenders-slug-algo'

    export default {
        mixins: [   
            mixins,
            subscriptions, 
        ],
        components:{
            LoanDetails,
            TopLoanMatches,
            OtherResults,
            Disclaimer,
            MoreInfoModal,
            CompareLenders
        },
        data() {
            return {
                loan: null,

                saving: false,
                callDialog: false,
                lenderName: '',
                lenderData: {},
                person: null,
                lenderObj: null,

                requiredAmount: '',
                leadLoanAmnount: 0,
                userInput: null,


                dialog: false,
                dialogData: null,

                // loading for running-quote
                isLoading: true,


                quotes: {},
                lenders: [],

                topLenders: [],
                otherLenders: [],

                resources: {                        
                    "type": "loans",
                    "id": null,
                    "attributes": {
                        "selected-lender-quote-algorithm-slug": null,

                    },
                    "relationships": {
                        "application": {
                            "data": {
                                "type": "applications",
                                "id": null
                            },
                        }
                    }
                },
                resourceObj: {

                },
                fields: {
                    'selected-lender-quote-algorithm-slug': { label: 'Lender', meta: 'selected-lender-quote-algorithm-slug' }
                },
                settings: {
                    hideLenderNames: 0,
                    customerDiscountRate: 0,
                    originationFee: 1000
                },
                productAlias: [ 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'C' ],
            }
        },
        beforeCreate() {
            window.scrollTo(0, 0)
        },
        mounted(){
            Object.keys(this.blockSettings).forEach(item => {

                if(this.settings.hasOwnProperty(item)){
                    this.settings[item] = this.blockSettings[item]
                }
            })
        },
        computed: {
            ...mapState({
                appGuid: state => state.flows.appGuid,
            }),
            ...mapGetters('slug', [
                'getGroupSlug',
            ]),
            getLoanId(){
                return this.appData.relationships.loan.data.id
            },
            getPersonId(){
                return this.appData.relationships.people.data[0].id
            },
            hideLenderIndentity(){
                return this.settings.hideLenderNames == 1
            },
            getSelectedLender(){
                if(this.resourceObj['selected-lender-quote-algorithm-slug']) {
                    return this.resourceObj['selected-lender-quote-algorithm-slug']
                }

                return ''
            },
            hasLenders(){
                return this.lenders.length > 0
            },
            selectedLendersToCompare(){
                return store.lendersToCompare
            },
            selectedLendersToCompareCount(){
                return store.lendersToCompare.length
            },
            loanAmountHasValue(){

                if(this.loan && this.loan.attributes['requested-required-loan-amount']) {

                    return true   

                } else {

                    if(this.leadLoanAmnount) {
                        return true
                    }
                    
                    return false   
                }

            }
        },
        methods: {
            ...mapActions('flows', ['selectNextStepOL']),
            ...mapActions('resource-actions', [
                'getQuotesPL',
                'updateLoan',
                'getPerson',
                'updatePerson',
                'updatePurchases',
                'setApplicationNow',
                'runAssisterSubmit',
            ]),
            ...mapActions('dialogs', [
                'evaluateSteps'
            ]),
            ...mapActions('flows', [
                'forceCompleteApplication'
            ]),
            async pull() {

                // Update Loan first with user testing values before getting the getQuotes

                // get person
                if(this.getPersonId) {
                    let res = await this.getPerson(this.getPersonId)
                    if(res.status == 200) {
                        this.person = res.data.data
                    }
                }
                
                if (this.getLoanId) {

                    let payload = {
                        type: 'loans',
                        id: this.getLoanId,
                        attributes: {
                            "customer-discount-rate": this.settings.customerDiscountRate,
                            "requested-broker-fee": this.settings.originationFee
                        }
                    }

                    if(this.isUnsecured){
                        payload.attributes['requested-payment-frequency'] = "Monthly"
                    }

                    let loanRes = await this.updateLoan(payload)

                    // get latest loan
                    if(loanRes && loanRes.status === 200) {
                        this.loan = loanRes.data.data
                    }

                    // Trigger pull method of child component
                    if(this.$refs.loanDetails) {
                        this.$refs.loanDetails.init()
                    }
                }

                if(this.loanAmountHasValue) {
                    this.updateLenderResults()
                }

            },
            async push() {
                const {valid, status} = await this.validateFormData()
                return  { valid, status, data: {} } 
            },
            async updateLenderResults(){

                this.isLoading = true

                try {
                    let response = await this.getQuotesPL({                    
                        'type': 'applications',
                        'id': this.appId,
                        'attributes': {}
                    })

                    if ( response.length > 1 ) {

                        response.forEach( res => {
                            if (res.data.data?.type === 'applications') {

                                let list = res?.data?.included?.[0]?.attributes?.results ? res?.data.included[0].attributes.results : {}
                                
                                if ( !isEmpty(list) ) {
                                    list = JSON.parse( list )
                                }

                                this.quotes = list?.lenders
                                this.requiredAmount = list?.['required-amount']
                                this.userInput = list?.['inputs']

                            } else {
                                
                                this.resources = res.data.data
                                this.resources.relationships = this.removeEmptyRelationships(this.resources.relationships)
                            }
                        })
                    }
                    
                } catch (error) {
                    console.log(error);
                }


                if (this.quotes){

                    Object.keys(this.quotes).forEach( (lender, index) => {
                        this.lenders.push(this.quotes[lender])
                        this.lenders[index]['slug'] = lender
                        this.lenders[index]['alias'] = `Product ${this.productAlias[index]}`
                    })
                }


                this.resourceObj = { 
                    'selected-lender-quote-algorithm-slug': this.resources.attributes['selected-lender-quote-algorithm-slug']
                }

                // sort lenders from lowest to highest monthly repayments

                if (this.lenders.length > 0){

                    this.lenders = this.sortLenders(this.lenders)
                    // this.lenders = this.lenders.filter(item => item.name != 'Wisr PL');
                    this.topLenders = this.lenders.slice(0, 3)


                    if(this.lenders.length > 3){
                        this.otherLenders = this.lenders.slice(3);
                    }

                }


                this.isLoading = false

                this.validComponentData = this.lenders.length > 0 ? true : false
            },
            async handleLoanDetailsSubmit(loan){

                this.resetLenders()

                if(loan) {
                    this.leadLoanAmnount = loan?.attributes?.['requested-required-loan-amount']
                }

                await this.updateLenderResults()

            },
            resetLenders(){

                this.lenders = []
                this.topLenders = []
                this.otherLenders = []
                this.quotes = {}
            },
            sortLenders(lenders) {

                let data = []

                lenders.forEach(item => {
                    data.push(item)
                })

                return data;
            },
            handleContinue(){

                this.resourceObj = { 'selected-lender-quote-algorithm-slug': "No options available" }
                this.selectNextStepOL({goToNext: true, returnStatus: false, params: {key: "source", value: "request-a-call"}})
                
            },
            setSelected(name = '', lender = {}) {

                this.resourceObj = { 'selected-lender-quote-algorithm-slug': name }

                this.populateLoanFields(lender)

                this.selectNextStepOL({goToNext: true, returnStatus: false, params: {key: "source", value: "apply-now"}})

            },
            populateLoanFields(item) {

                this.resources.attributes['quoted-balloon'] = item['actual-payment']['balloon']
                this.resources.attributes['quoted-bank-fees'] = item['actual-payment']['bank-fees']
                this.resources.attributes['quoted-base-rate'] = item['actual-payment']['base-rate']
                this.resources.attributes['quoted-broker-commission-inc-gst'] = item['actual-payment']['commission-inc-gst']
                this.resources.attributes['quoted-broker-fee'] = item['actual-payment']['broker-fee']
                this.resources.attributes['quoted-customer-rate'] = item['actual-payment']['customer-rate']
                this.resources.attributes['quoted-monthly-payments'] = item['actual-payment']['monthly-payment']
                this.resources.attributes['quoted-naf'] = item['actual-payment']['naf']
                this.resources.attributes['quoted-account-fee'] = item['actual-payment']['account-fee']
                this.resources.attributes['quoted-term-months'] = item['actual-payment']['term-in-months']
                this.resources.attributes['selected-lender-quote-algorithm-slug'] = item.name

            },
            removeEmptyRelationships(relationships) {
                
                let temp = {}

                Object.keys(relationships).forEach( key => {
                    if (relationships[key].data?.length > 0 || relationships[key].data?.id !== undefined)
                        temp[key] = relationships[key]
                })

                return temp
            },



            showInfoDialog(data){
                this.dialogData = data
                this.dialog = true
            },
            async showApplyModal(param) {

                this.lenderObj = param
                await this.submitToNextStep(param.name, param.slug)
            },
            async submitToNextStep(lender, slug){

                try {

                    this.saving = true

                    if(lender) {

                        const {lenderObj} = this

                        // console.log('lenderObj', this.lenderObj)

                        let selectedLender = lenderSlugAlgo.personal.find(lender => lender['lender-slug-algorithm'] === slug)

                        let payload = {
                            type: 'loans',
                            id: this.getLoanId,
                            attributes: {
                                'quoted-balloon': lenderObj?.['actual-payment']['balloon'],
                                'quoted-bank-fees': lenderObj?.['actual-payment']['bank-fees'],
                                'quoted-base-rate': lenderObj?.['actual-payment']['base-rate'],
                                'quoted-broker-commission-inc-gst': lenderObj?.['actual-payment']['commission-inc-gst'],
                                'quoted-broker-fee': lenderObj?.['actual-payment']['broker-fee'],
                                'quoted-customer-rate': lenderObj?.['actual-payment']['customer-rate'],
                                'quoted-monthly-payments': lenderObj?.['actual-payment']['monthly-payment'],
                                'quoted-naf': lenderObj?.['actual-payment']['naf'],
                                'quoted-account-fee': lenderObj?.['actual-payment']['account-fee'],
                                'quoted-term-months': lenderObj?.['actual-payment']['term-in-months'],
                                "selected-lender-quote-algorithm-slug": slug,
                            }
                        }

                        let res = await this.updateLoan(payload)

                        if(res.status === 200) {

                            try {

                                this.saving = true

                                let assisterSubmissions = {
                                    "set-target-only": 1,
                                    'application': this.appGuid 
                                }
                                
                                let res = await this.runAssisterSubmit(assisterSubmissions)
                                
                                if(res.status == 200) {

                                    this.forceCompleteApplication()
                                }

                                this.saving = false

                            } catch (err) {
                                console.log(err)
                                this.saving = false
                            }
                            
                        }

                    }

                } catch (err) {
                    console.log(err)
                }

                this.saving = false

            },
            closeModal() {
                this.callDialog = false
            }

        }
    }
</script>

<style lang="scss">

    .flex__component-QuoteResult6 {

        .top-lenders-slide-group {

            .v-slide-group__wrapper .v-slide-group__content {
                justify-content: center !important;
            }

            .lender-item .v-card__actions {
                cursor: pointer;
                justify-content: center !important;
            }

        }


        .outline {
            border: none;
            height: 1px;
        }


        .flex-form {
            display: grid;
            grid-column-gap: 15px;
            grid-row-gap: 0;
            grid-template-columns:  repeat(auto-fit, minmax(260px, 1fr));

            .flex-field {
                align-self: start;
            }
        }

        .inline-icons {
            display: flex;
        }

        .caption.text-center.gray {
            color: gray;
        }

        .lightgray {
            background-color: #fafafa;
        }

    }
  
</style> 